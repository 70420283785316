<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5 mt-0.5">
      <input
          :id="name"
          :name="label"
          type="checkbox"
          class="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 dark:bg-zinc-700 dark:border-zinc-600 rounded"
          v-model="checked"
          :ref="name"
      />
    </div>
    <div class="ml-2 text-sm">
      <label :for="name" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-0.5">{{ label }}</label>
      <p v-if="description" class="text-sm text-gray-500 max-w-md">
        {{ description }}
      </p>
      <p v-if="error" class="mt-2 text-sm text-red-600">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    modelValue: Boolean,
    name: String,
    label: String,
    description: String,
  },
  computed: {
    checked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    error() {
      return null;
    },
  },
};
</script>